<template>
  <div class="detail-box w">
    <!-- <div class="topbox"> -->
    <div class="inputbox">
      <el-input
        placeholder="请输入您要搜索的课程"
        v-model="historyparam.albumName"
        class="input-with-select"
        @keyup.enter.native="beginSearch()"
      >
        <template slot="prepend"
          ><img class="searchimg" src="./images/search.png" alt=""
        /></template>
        <el-button slot="append" @click="beginSearch()">搜索</el-button>
      </el-input>
    </div>
    <div class="courselist cursorpointer" @click="gocourselist">
      <i class="lefticon"></i>
      <p class="font16500 green1">课程列表</p>
    </div>
    <!-- </div> -->
    <div class="historycontent">
      <div class="shuoming">
        <img src="./images/sming.png" alt="" />
        <p class="font14400 green1">我们将为您保存180天的历史记录</p>
      </div>
      <!-- <div class="hisitem">
        <span class="timebutton font20500 green1">今天</span>
        <div class="content">
          <div class="leftline"></div>
        </div>
      </div> -->
      <div class="hiscontent">
        <el-timeline>
          <el-timeline-item
            v-for="(his, index) in historyList"
            :key="index"
            :timestamp="his.handletime"
            placement="top"
          >
            <span
              class="timebutton font20500 green1"
              v-if="
                index === 0 ||
                his.handledate != historyList[index - 1].handledate
              "
              >{{ his.handledate }}</span
            >
            <el-card>
              <div class="carditem">
                <div
                  class="cardleft cursorpointer"
                  @click="toCurseDetail(his.id, his.albumName, '', his)"
                >
                  <img :src="his.coverId" alt="" /><span class="font16400 white"
                    >共{{ his.total }}集</span
                  >
                </div>
                <div class="cardcenter">
                  <p class="font20500 black2 lineheight15">
                    {{ his.albumName }}
                  </p>
                  <p class="font18400 grey1 lineheight2">{{ his.teacher }}</p>
                  <div class="viewde">
                    <img src="./images/computer.png" alt="" /><span
                      class="font16400 grey2"
                      >观看至 <e class="iszhanshi">{{ his.latestClassName }}</e
                      >{{ his.handlepress }}</span
                    >
                  </div>
                </div>
                <div class="cartright" @click="deleteView(his.id)">
                  <!-- <img src="./images/del.png" alt="" /> -->
                </div>
              </div>
            </el-card>
          </el-timeline-item>
        </el-timeline>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getLatestView, delhistory
} from "@/api"
// import { ContactCard } from 'vant'
export default {
  name: "History",
  data () {
    return {
      historyparam: {
        albumName: '',
        pageNum: 1,
        pageSize: 100000
      },
      input3: '',
      historyList: [],
      historyHandle: []


    }
  },
  mounted () {
    this.getLatestView()
  },
  methods: {
    // 将视频时长(秒)转化为时分秒的形式
    // getTime (value) {
    //   let secondTime = parseInt(value / 1000) // 秒
    //   let minuteTime = 0 // 分
    //   let hourTime = 0 // 时
    //   if (secondTime > 60) {
    //     //如果秒数大于60，将秒数转换成整数
    //     //获取分钟，除以60取整，得到整数分钟
    //     minuteTime = parseInt(secondTime / 60)
    //     //获取秒数，秒数取余，得到整数秒数
    //     secondTime = parseInt(secondTime % 60)
    //     //如果分钟大于60，将分钟转换成小时
    //     if (minuteTime > 60) {
    //       //获取小时，获取分钟除以60，得到整数小时
    //       hourTime = parseInt(minuteTime / 60)
    //       //获取小时后取余的分，获取分钟除以60取余的分
    //       minuteTime = parseInt(minuteTime % 60)
    //     }
    //   }
    //   //若秒数是个位数，前面用0补齐
    //   secondTime = secondTime < 10 ? "0" + secondTime : secondTime
    //   var result = "" + secondTime + ""
    //   if (minuteTime > 0) {
    //     //若分钟数是个位数，前面用0补齐
    //     minuteTime = minuteTime < 10 ? "0" + minuteTime : minuteTime
    //     result = "" + minuteTime + ":" + result
    //   } else {
    //     //若分钟数为0，用"00"表示
    //     result = "" + "00" + ":" + result
    //   }

    //   if (hourTime > 0) {
    //     //若小时数是个位数，前面用0补齐
    //     hourTime = hourTime < 10 ? "0" + hourTime : hourTime
    //     result = "" + hourTime + ":" + result
    //   } else {
    //     //若小时数为0，用"00"表示
    //     result = "" + "00" + ":" + result
    //   }
    //   console.log("result", result)
    //   return result
    // }
    // ,
    beginSearch () {
      this.getLatestView()
    },
    toCurseDetail (albumId, albumName, subjectId, albums) {
      this.$router.push({ name: 'CourseDetail', query: { albumId, albumName }, params: { albums } })
    },
    gocourselist () {
      this.$router.push({ path: '/course' })
    },
    getLatestView () {
      getLatestView(this.historyparam).then((res) => {
        if (res.code == 200) {
          this.historyList = res.rows
          this.handleData()

        }
      })
    },
    deleteView (id) {
      delhistory({ albumId: id }).then((res) => {
        console.log(res, 'res')
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.getLatestView()
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    handleData () {
      this.historyList.forEach(ele => {
        let timearr = ele.maxUpdateTime.split("T")
        ele.handledate = this.handleDateBySel(ele.maxUpdateTime)
        ele.handletime = timearr[1].split(".")[0]
        ele.handlepress = this.handlePress(ele.latestProgress)

      })

    },
    handleDateBySel (date) {
      let year = new Date(date).getFullYear()
      let month = new Date(date).getMonth()
      let ri = new Date(date).getDate()

      let nowyear = new Date().getFullYear()
      let nowmonth = new Date().getMonth()
      let nowri = new Date().getDate()

      if (year == nowyear && month == nowmonth && ri == nowri) {
        return "今天"
      } else if (ri == (new Date().getDate() - 1) && year == nowyear && month == nowmonth) {
        return "昨天"
      } else {
        return `${year}/${month}/${ri}`

      }


    },

    handlePress (value) {
      let s = parseInt(value / 1000) // 秒
      let hours = 0  //定义一个变量保存 小时

      //获取分钟，除以60取整数，得到整数分钟
      let minutes = Math.floor(s / 60)  //分钟

      //获取秒数，秒数取余，得到整数秒数
      let seconds = this.zeroFill(s % 60)  //秒

      //如果分钟大于60，将分钟转换成小时
      if (minutes > 60) {
        // 获取小时整数
        hours = this.zeroFill(Math.floor(minutes / 60))
        // 获取小时后取余的分钟数
        minutes = this.zeroFill(minutes % 60)
      }
      // 补零  如果小于10就在数字前面加一个零

      return hours + ":" + minutes + ":" + seconds
    },
    zeroFill (n) {
      return n < 10 ? "0" + n : n
    }

  }

}
</script>

<style lang="less" scoped>
@import url(../../assets/css/common.css);
.detail-box {
  padding: 130px 0 80px;
  position: relative;
  .inputbox {
    text-align: center;
  }
  .courselist {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 142px;
    left: 0;
    width: 116px;
    height: 36px;
    border-radius: 4px;
    background: rgba(255, 255, 255, 1);
    backdrop-filter: blur(10px);

    .lefticon {
      display: block;
      width: 24px;
      height: 24px;
      background: url("./images/left.png");
      margin-right: 8px;
    }
  }
  .historycontent {
    width: 100%;
    min-height: 68vh;
    margin-top: 38px;
    border-radius: 8px;
    background: rgba(255, 255, 255, 0.6);

    border: 2px solid rgba(255, 255, 255, 1);

    backdrop-filter: blur(20px);
    padding: 30px 60px;
    .shuoming {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      img {
        width: 24px;
        height: 24px;
      }
    }
    .hiscontent {
      margin-top: 20px;
    }
    .hisitem {
      .content {
        width: 100%;
        height: 196px;
        .leftline {
          width: 5px;
          height: 100%;
          left: 397px;
          background: rgba(255, 255, 255, 1);
        }
      }
    }
  }
  .el-input {
    img {
      width: 24px;
      height: 24px;
    }
  }
  .el-input-group {
    width: 744px;
    height: 60px;
    border-radius: 12px;
    background: rgba(255, 255, 255, 0.6);

    border: 2px solid rgba(255, 255, 255, 1);

    backdrop-filter: blur(20px);
  }
  .timebutton {
    // width: 66px;
    height: 32px;
    line-height: 32px;
    padding: 0 5px;
    opacity: 1;
    border-radius: 6px;
    background: rgba(255, 255, 255, 1);
    position: absolute;
    left: 0;
    top: -25px;
    transform: translateX(-30%);
  }
  /deep/.el-input-group__append {
    border: none;
    width: 111px;
    height: 52px;
    border-radius: 12px;
    background: rgba(67, 207, 124, 1);
    font-size: 18px;
    font-weight: 500;
    color: rgba(255, 255, 255, 1);
    text-align: center;
  }
  /deep/.el-input-group__prepend {
    border: none;
    background-color: transparent;
  }
  /deep/.el-input__inner {
    height: 100%;
    border: none;

    font-size: 20px;
    font-weight: 400;
    color: rgba(29, 29, 31, 1);

    // color: rgba(170, 170, 170, 1);
  }
}
/deep/.el-card {
  border-radius: 8px;
  border: none;
}
/deep/.el-card.is-always-shadow,
.el-card.is-hover-shadow:focus,
.el-card.is-hover-shadow:hover {
  box-shadow: none;
}
/deep/.el-timeline-item__wrapper {
  display: flex;
  align-items: center;
}
/deep/.el-timeline-item__timestamp {
  margin-right: 20px;
}
/deep/.el-timeline-item__content {
  flex: 1;
}
/deep/.el-card__body {
  height: 156px;
  padding: 10px 30px 10px 10px;
}
/deep/.el-timeline-item__node {
  top: 40%;
}
/deep/.el-timeline .el-timeline-item:last-child .el-timeline-item__tail {
  display: block;
}
/deep/.el-timeline-item__tail {
  border-left: 5px solid #fff;
}
/deep/.el-timeline-item__node--normal {
  width: 6px;
  height: 6px;
  left: 3.5px;
}
/deep/.el-timeline-item__node {
  background-color: rgba(170, 170, 170, 1);
}
.carditem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .cardleft {
    position: relative;
    margin-right: 20px;
    img {
      width: 240px;
      height: 136px;
      border-radius: 6px;
    }
    span {
      height: 24px;
      opacity: 1;
      padding: 0 5px;
      border-radius: 4px;
      background: rgba(0, 0, 0, 0.5);
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }
  .cardcenter {
    flex: 1;
  }
  .viewde {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-right: 5px;
    img {
      width: 24px;
      height: 24px;
    }
    .iszhanshi {
      display: inline-block;
    }
  }
  .cartright {
    cursor: pointer;
    width: 30px;
    height: 30px;
    background: url("./images/del.png") no-repeat center;
    background-size: 24px 24px;
    // img {
    //   width: 24px;
    //   height: 24px;
    // }
  }
  .cartright:hover {
    border-radius: 50%;
    background: rgba(204, 204, 204, 0.19) url("./images/del-act.png") no-repeat
      center;
  }
}
@media (max-width: 1116px) {
  .el-input-group {
    width: 500px !important;
  }
  .courselist {
    width: 84px !important;
    height: 60px !important;
    flex-wrap: wrap !important;
    top: 132px !important;
  }
  .iszhanshi {
    display: none !important;
  }
}
</style>